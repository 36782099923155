.neo-checkbox-container {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    flex-flow: row;
}

.neo-checkbox-label {
    font-size: 18px;
    color: #587FAA;
    text-shadow: 0 1px white;
    margin: 0;
    user-select: none;
    cursor: default;
    font-weight: 600;
    padding: 0;
    margin-left: 15px;
    width: 70%;
}

input.neo-checkbox-checkbox {
    transition: all 0.2s;
    min-width: 34px;
    max-width: 34px;
    height: 34px;
    background-color: white;
    font-size: 18px;
    font-weight: 600;
    color: #567DA8;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    border: 6px solid white;
    -webkit-appearance: none;
    outline: none;
    cursor: default;
    box-shadow: 10px 10px 21px -8px #B6C6D4, -10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}

input.neo-checkbox-checkbox:checked {
    background-color: #567DA8;
    border: 6px solid white;
    box-shadow: 10px 10px 21px -8px #B6C6D4, -10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}