.neo-bar {
	width: 100%;
	height: 50px;
	background-color: white;
	position: fixed;
	z-index: 100;
}

.neo-bar-content {
	width: calc(100% - 60px);
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

.neo-bar-content-vendor {
	width: 100%;
	position: fixed;
}

.neo-bar-storeButton {
	transition: all 0.2s;
	width: 100%;
	background-color: rgba(48, 147, 252, 1);
	height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 13px;
	font-weight: 500;
	margin-top: 50px;
	color: white;
}

.neo-bar-storeButton:hover {
	background-color: rgba(71, 160, 255, 1);
}

.neo-bar-storeButton-profilePicture {
	width: 26px;
	height: 26px;
	background-color: rgba(48, 147, 252, 0.14);
	border-radius: 13px;
	margin-right: 10px;
	text-align: center;
	line-height: 25px;
	font-size: 12px;
	font-weight: 700;
	color: rgba(48, 147, 252, 1);
}

.neo-bar-storeButton-profilePicture#bar {
	background-color: rgba(0, 0, 0, 0.2);
	color: white;
}

div.neo-bar-logo {
	width: 141.5px;
	height: 50px;
	background-size: 141.5px 50px;
	margin-top: 12px;
	margin-left: auto;
	margin-right: auto;
}

div.neo-bar-logo-alt {
	transition: all 0.1s;
	width: 150px;
	height: 44px;
	background-size: 143.5px 36px;
	margin-top: 3px;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 8px;
	margin-left: auto;
	margin-right: auto;
}

div.neo-bar-logo-alt:hover {
	background-color: rgba(0, 0, 0, 0.06);
}

div.neo-bar-logo-alt:active {
	background-color: rgba(0, 0, 0, 0.12);
}

div.neo-bar-backButton {
	transition: all 0.2s;
	position: absolute;
	width: 32px;
	height: 32px;
	background-color: transparent;
	margin-top: 7px;
	border-radius: 8px;
	background-image: url("./BackButton.png");
	background-size: 32px 32px;
}

@media (hover: hover) and (pointer: fine) {
	div.neo-bar-backButton:hover {
		background-color: rgba(0, 0, 0, 0.06);
	}
}

div.neo-bar-backButton:active {
	background-color: rgba(0, 0, 0, 0.22);
}

p.neo-bar-title {
	font-size: 34px;
	color: black;
	font-weight: 700;
	user-select: none;
	cursor: default;
	margin-top: 0;
	margin-bottom: 30px;
}

p.neo-bar-debug {
	position: absolute;
	font-size: 13px;
	color: darkred;
	font-weight: 700;
	margin-top: 0;
	float: right;
	line-height: 50px;
	top: 0;
	right: 60px;
}
