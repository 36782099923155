.neo-button {
	transition: box-shadow 0.25s ease;
	width: 100%;
	height: 50px;
	background-color: #e9edf0;
	border: none;
	margin-bottom: 15px;
	font-size: 17px;
	color: #587faa;
	font-weight: bold;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}

.neo-button#destructive {
	color: #d82828;
}

.neo-button:hover {
	box-shadow: 10px 17px 21px -8px #97a6b3,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.8);
}

.neo-button:active {
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
}

.neo-button:disabled {
	background-color: white;
	box-shadow: none;
	opacity: 0.6;
}

.neo-button#loading {
	box-shadow: none;
	border: 2px solid rgba(0, 0, 0, 0.1);
}

.neo-button-loadingContainer {
	text-align: center;
}
