.neo-dropdown {
	transition: all 0.25s ease;
	box-sizing: border-box;
	width: 100%;
	height: 60px;
	line-height: 50px;
	margin-bottom: 20px;
	overflow: hidden;
	background-color: #e9edf0;
	margin-bottom: 40px;
	font-size: 18px;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}

.neo-dropdown:hover {
}

.neo-dropdown-title {
	height: 50px;
	transition: background-color 0.25s ease;
	font-size: 15px;
	color: #587faa;
	font-weight: bold;
	text-align: center;
	user-select: none;
	cursor: default;
	padding: 0;
	background-color: rgba(255, 255, 255, 0.5);
	margin: 5px;
	border-radius: 7px;
}

.neo-dropdown-title:hover {
	background-color: rgba(255, 255, 255, 0.6);
}

.neo-dropdown-title:active {
	background-color: rgba(0, 0, 0, 0.04);
}
