.neo-selectField-container {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 1px 0 white;
    float: left;
}

.neo-selectField-label {
    width: 50%;
    font-size: 18px;
    color: #587FAA;
    font-weight: 400;
    text-shadow: 0 1px white;
    margin-top: 0;
    user-select: none;
    cursor: default;
    line-height: 50px;
    float: left;
}

select.neo-SelectField-select {
    height: 50px;
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    color: #567DA8;
    text-shadow: 0 1px 0 white;
    float: right;
    margin: 0;
    padding: 0;
    text-align: right;
    border-radius: 0;
    -webkit-appearance: none;
}

select.neo-SelectField-select:disabled {
    color: #567DA8;

    opacity: 1; /* Needed for Safari */
    -webkit-text-fill-color: #567DA8; /* Needed for Safari */
}