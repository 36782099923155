.neo-editField-container {
	width: 100%;
	height: 50px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.7);
	float: left;
}

.neo-editField-label {
	width: 50%;
	font-size: 18px;
	color: #587faa;
	font-weight: 400;
	text-shadow: 0 1px white;
	margin-top: 0;
	user-select: none;
	cursor: default;
	line-height: 50px;
	float: left;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

input.neo-editField-textField {
	width: 50% !important;
	height: 50px !important;
	border: none !important;
	box-shadow: none !important;
	background-color: transparent;
	font-size: 18px !important;
	font-weight: 600 !important;
	color: #567da8 !important;
	text-shadow: 0 1px 0 white !important;
	float: right !important;
	margin: 0 !important;
	padding: 0 !important;
	text-align: right !important;
	border-radius: 0 !important;
}

input.neo-editField-textField:disabled {
	color: #567da8;

	opacity: 1; /* Needed for Safari */
	-webkit-text-fill-color: #567da8; /* Needed for Safari */
}
