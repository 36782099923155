body {
	margin: 0;
	background-color: #e9edf0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
}

.id-cards-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	gap: 20px;
}

.id-card-preview {
	position: relative;
	min-width: 50px;
	max-height: 200px;
	box-sizing: border-box;

	background-color: white;
	text-align: center;
	border-radius: 8px;
	box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.neo-card-cell {
	box-sizing: border-box;
	width: 100%;
	height: 70px;
	background-color: rgba(255, 255, 255, 0.8);
	margin-bottom: 10px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: left;
	align-items: center;
	border-radius: 8px;
}

.neo-spacer20 {
	width: 100%;
	height: 20px;
	float: left;
}

.neo-multistoreBanner {
	width: 100%;
	max-width: 340px;
	background-color: white;
	position: fixed;
	border-radius: 10px;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 10px;
	top: 84px;
	box-sizing: border-box;
}

.neo-privacyBanner {
	width: calc(100% - 60px);
	margin-left: 30px;
	background-color: white;
	position: fixed;
	border-radius: 10px;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 10px;
	box-sizing: border-box;
}

.copyBanner {
	width: calc(100% - 60px);
	max-width: 700px;
	transition: all 0.4s;
	background-color: white;
	position: fixed;
	top: 10px;
	border-radius: 25px;
	box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
	padding: 15px;
	box-sizing: border-box;
	z-index: 100;
	text-align: center;
	font-weight: 600;
}

.neo-privacyBanner-button {
	transition: all 0.25s ease;
	width: 100%;
	height: 50px;
	background-color: rgba(0, 0, 0, 0.12);
	border: none;
	margin-bottom: 30px;
	font-size: 18px;
	color: black;
	font-weight: bold;
	border-radius: 25px;
	text-align: center;
	line-height: 49px;
}

.neo-privacyBanner-button:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

.neo-privacyBanner-button:active {
	background-color: rgba(0, 0, 0, 0.25);
}

.neo-divider {
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	box-shadow: 0 1px 0 white;
	margin-bottom: 30px;
	float: left;
}

.separator {
	display: flex;
	align-items: center;
	text-align: center;
	width: 100%;

	font-size: 18px;
	color: #587faa;
	font-weight: 700;
	text-shadow: 0 1px white;
	user-select: none;
	cursor: default;
	margin-top: 10px;
	margin-bottom: 30px;
	float: left;
}
.separator::before,
.separator::after {
	content: "";
	flex: 1;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	box-shadow: 0 1px 0 white;
}
.separator::before {
	margin-right: 10px;
}
.separator::after {
	margin-left: 10px;
}

.neo-list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-top: 2em;
}

.neo-centerContainer {
	width: 100%;
	text-align: center;
}

.neo-loadingSpinner {
	width: 60px;
	height: 60px;

	position: relative;
	margin: 100px auto;
}

.neo-loadingSpinner-small {
	width: 20px;
	height: 20px;
	position: relative;
	margin: 11px auto;
}

.neo-map {
	width: 100%;
	height: 300px;
	margin-top: -20px;
	margin-bottom: 20px;
	overflow: hidden;
}

.neo-map-large {
	width: 100%;
	height: calc(100vh - 270px);
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 15px;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 4px 0 white;
	border: 1px solid rgba(0, 0, 0, 0.1);
	overflow: hidden;
}

.double-bounce1,
.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	background-color: #587faa;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;

	-webkit-animation: sk-bounce 2s infinite ease-in-out;
	animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

a {
	transition: background-color 0.25s ease;
	text-decoration: none;
	color: #587faa;
	font-weight: 500;
	text-shadow: 0 1px white;
	padding: 10px;
	border-radius: 10px;
}

a.termsButton {
	transition: background-color 0.25s ease;

	color: #587faa;
	font-weight: 500;
	text-shadow: 0 1px white;
	margin-left: 39px;
	padding: 10px;
	border-radius: 10px;
	margin-top: 0;
}

@media (hover: hover) and (pointer: fine) {
	a:hover {
		text-decoration: underline;
	}
}

.neo-app {
	width: 100%;
	margin: 0;
	background-color: #e9edf0;
	float: left;
}

.neo-content {
	width: calc(100% - 60px);
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 120px;
	padding-bottom: 70px;
}

.neo-messageContainer {
	width: 100%;
	margin-top: 80px;
}

.neo-card {
	box-sizing: border-box;
	padding: 20px;
	width: 100%;
	background-color: #e9edf0;
	margin-bottom: 40px;
	font-size: 18px;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}

.neo-statusIndicator {
	width: fit-content;
	padding: 10px 15px;
	background-color: rgba(0, 0, 0, 0.05);
	font-size: 13px;
	text-transform: uppercase;
	border-radius: 25px;
	text-align: center;
	color: #f7b500;
	font-weight: 700;
	user-select: none;
	cursor: default;
	margin: 30px auto 40px;
}

.neo-qr-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.neo-qr-scanButton {
	width: 200px;
	height: 200px;
	background-color: rgba(0, 0, 0, 0.02);
	border-radius: 10px;
	background-image: url("./qr_scan.png");
	background-size: 100px 100px;
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 4px 0 white;
}

.neo-qr {
	width: 100%;
	height: 100%;
	max-width: 300px;
	box-sizing: border-box;

	text-align: center;
	border-radius: 15px;
	padding: 20px;
	margin-left: auto;
	margin-right: auto;
}

.neo-hiddenUpload {
	display: none;
}

.neo-overlay {
	position: absolute;
	box-sizing: border-box;
	color: #f1f1f1;
	color: white;
	bottom: 15px;
}

.neo-idCardPreview {
	display: block;
	width: 100%;
	height: auto;
	min-width: 200px;
	min-height: 200px;
	max-width: 100%;
	max-height: 400px;
	box-sizing: border-box;

	background-color: white;
	text-align: center;
	border-radius: 15px;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 4px 0 white;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.neo-contractPreview {
	width: 100%;
	height: 100%;
	max-width: 200px;
	box-sizing: border-box;

	background-color: white;
	text-align: center;
	border-radius: 15px;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.3), 0 1px 4px 0 white;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 10px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}

.neo-footer {
	box-sizing: border-box;
	width: 100%;
	text-align: center;
	float: left;
	color: #587faa;
	line-height: 20px;
	padding: 20px;
}

.neo-flex {
	width: 90%;
	margin-left: 5%;
	margin-right: 5%;
	display: flex; /* establish flex container */
	flex-direction: column; /* make main axis vertical */
	justify-content: center; /* center items vertically, in this case */
	align-items: center; /* center items horizontally, in this case */
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

p.neo-largeTitle {
	font-size: 46px;
	color: #587faa;
	font-weight: 700;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	margin-top: 0;
	cursor: default;
	word-wrap: break-word;
}

p.neo-largeTitle#leading {
	text-align: left;
}

p.neo-mediumTitle {
	font-size: 26px;
	color: #587faa;
	font-weight: 700;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	margin-top: 0;
	user-select: none;
	cursor: default;
}

p.neo-mediumTitle#leading {
	text-align: left;
}

p.neo-smallTitle {
	font-size: 18px;
	color: #587faa;
	font-weight: 700;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	cursor: default;
	margin-top: 15px;
}

p.neo-callout {
	font-size: 13px;
	color: #6d7278;
	font-weight: 700;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	cursor: default;
	margin-top: 5px;
	text-align: center;
}

p.neo-text {
	font-size: 16px;
	color: #587faa;
	font-weight: 500;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	text-shadow: 0 1px white;
	margin-bottom: 30px;
}

label.neo-text {
	font-size: 16px;
	color: #587faa;
	font-weight: 500;
	display: flex;
	flex-direction: column;
	gap: 4px;
}

p.neo-text#leading {
	text-align: left;
	white-space: pre-line;
}

p.neo-textSmall {
	font-size: 15px;
	color: #587faa;
	font-weight: 500;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	text-shadow: 0 1px white;
	margin-bottom: 30px;
	margin-top: 0;
	cursor: default;
}

div.neo-errorContainer {
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 30px;
	padding: 20px;
	background-color: #e9edf0;
	border-radius: 10px;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
	float: left;
}

p.neo-errorText {
	font-size: 18px;
	color: #d82828;
	font-weight: 500;
	text-align: left;
	text-shadow: 0 1px white;
	user-select: none;
	cursor: default;
	margin-top: 0;
	margin-bottom: 10px;
}

p.neo-errorText#inset {
	font-size: 15px;
	color: #587faa;
	font-weight: 500;
	text-align: left;
	text-shadow: 0 1px white;
	user-select: none;
	cursor: default;
	margin-top: 0;
	margin-bottom: 0;
}

.neo-menuButton-icon {
	min-width: 58px;
	height: 58px;
	background-size: cover;
	background-position-y: 2px;
	float: left;
	margin-right: 10px;
}

div.neo-logo {
	width: 239px;
	height: 60px;
	background-size: 239px 60px;
	margin-bottom: 40px;
	margin-left: auto;
	margin-right: auto;
}

.neo-iconCircle {
	width: 140px;
	height: 140px;
	margin-left: auto;
	margin-right: auto;
	background-color: #e9edf0;
	border: none;
	margin-bottom: 40px;
	color: #587faa;
	border-radius: 70px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
	background-repeat: no-repeat;
	background-position: center;
	background-size: 80px 80px;
}

.neo-deleteButton {
	position: relative;
	transition: all 0.25s ease;
	width: 50px;
	height: 50px;
	background-color: #e9edf0;
	border: 2px solid #587faa;
	font-size: 18px;
	color: #587faa;
	font-weight: bold;
	border-radius: 29px;
	text-shadow: 0 1px white;
	margin-left: 20px;
	margin-top: 20px;
	background-image: url("./delete.png");
	background-size: 23px 27px;
	background-repeat: no-repeat;
	background-position: center;
}

.neo-deleteButton:hover {
	background-color: #dde1e3;
}

.neo-deleteButton:active {
}

.neo-statusIcon {
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	float: left;
	margin-right: 6px;
}

@keyframes zoomIn {
	0% {
		opacity: 0;
		transform: scale(1.07);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.flexContainer-vertical {
	width: 100%;
	height: 100vh;
	display: flex; /* establish flex container */
	flex-direction: column; /* make main axis vertical */
	justify-content: center; /* center items vertically, in this case */
	align-items: center; /* center items horizontally, in this case */
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.modal {
	animation: fadeIn 0.28s alternate ease-in-out;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
}

.modal-content {
	animation: zoomIn 0.28s alternate ease-in-out;
	box-sizing: border-box;
	width: 90%;
	max-width: 700px;
	max-height: calc(100% - 100px);
	margin-left: auto;
	margin-right: auto;
	background-color: #e9edf0;
	overflow: scroll;
	border-radius: 10px;
}

.modal-closeButton {
	position: fixed;
	transition: all 0.25s ease;
	width: 50px;
	height: 50px;
	background-color: #e9edf0;
	border: 2px solid #587faa;
	font-size: 18px;
	color: #587faa;
	font-weight: bold;
	border-radius: 29px;
	text-shadow: 0 1px white;
	margin-left: 20px;
	margin-top: 20px;
	background-image: url("./times-solid.svg");
	background-size: 23px 27px;
	background-repeat: no-repeat;
	background-position: center;
}

.userMenuWrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 10000000000;
}

.userMenu {
	animation: zoomIn 0.28s alternate ease-in-out;
	width: calc(100% - 40px);
	max-width: 400px;
	background-color: rgba(255, 255, 255, 1);
	margin-left: auto;
	margin-right: auto;
	margin-top: 94px;
	border-radius: 10px;
	overflow: hidden;
	box-shadow: 0 2px 40px rgba(0, 0, 0, 0.2);
}

.userMenuRow {
	transition: all 0.18s;
	width: 100%;
	height: 38px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: black;
	user-select: none;
	cursor: default;
	font-weight: 500;
	font-size: 15px;
	display: flex;
	justify-content: left;
	align-items: center;
	padding-left: 12px;
}

.userMenuRow:last-child {
	border-bottom: none;
}

.userMenuRow:hover {
	background-color: rgba(0, 0, 0, 0.05);
}

.userMenuRow:active {
	background-color: rgba(0, 0, 0, 0.1);
	color: var(--sidebar-bg-color);
}

.stores-card {
	width: 100%;
	background-color: white;
	padding: 25px;
	box-sizing: border-box;
	border-radius: 20px;
	margin-bottom: 20px;
}

.stores-hero {
	width: 210px;
	height: 120px;
	background-image: url("./stores-hero.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	margin-left: auto;
	margin-right: auto;
}

.stores-smallTitle {
	font-size: 22px;
	font-weight: bold;
	text-align: center;
	margin: 0;
}

.stores-text {
	font-size: 15px;
	text-align: center;
	color: gray;
	margin: 0;
	margin-top: 10px;
	margin-bottom: 20px;
}

.stores-button {
	transition: all 0.25s ease;
	width: 100%;
	height: 46px;
	background-color: rgba(0, 0, 0, 0.07);
	border: none;
	font-size: 15px;
	color: black;
	font-weight: bold;
	border-radius: 10px;
	text-shadow: none;
}

.stores-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.stores-button:active {
	background-color: rgba(0, 0, 0, 0.2);
}

.stores-button:disabled {
	background-color: white;
	box-shadow: none;
	opacity: 0.6;
}

.stores-cell {
	width: 100%;
	background-color: white;
	padding: 25px;
	padding-top: 15px;
	padding-bottom: 15px;
	box-sizing: border-box;
	border-radius: 20px;
	margin-bottom: 20px;
}

.stores-cell-text {
	font-size: 15px;
	color: gray;
	margin: 0;
	margin-top: 4px;
}

.stores-cell-title {
	font-size: 17px;
	color: black;
	margin: 0;
	font-weight: bold;
}
