input[type="text"] {
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
	background-color: transparent;
	font-size: 17px;
	font-weight: bolder;
	padding-left: 25px;
	padding-right: 25px;
	color: #567da8;
	text-shadow: 0 1px 0 white;
	-webkit-appearance: none;
}

input[type="email"] {
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
	background-color: transparent;
	font-size: 17px;
	font-weight: bolder;
	padding-left: 25px;
	padding-right: 25px;
	color: #567da8;
	text-shadow: 0 1px 0 white;
	-webkit-appearance: none;
}

input[type="password"] {
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	border: none;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
	background-color: transparent;
	font-size: 17px;
	font-weight: bolder;
	padding-left: 25px;
	padding-right: 25px;
	color: #567da8;
	text-shadow: 0 1px 0 white;
	-webkit-appearance: none;
}

input[type="date"] {
	-webkit-appearance: none;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
}

input[type="radio"] {
	font-size: 17px;
	font-weight: bolder;
	min-width: 34px;
	max-width: 34px;
	height: 34px;
	background-color: #e9edf0;
	font-size: 18px;
	font-weight: 600;
	color: #567da8;
	margin: 0;
	padding: 0;
	border-radius: 17px;
	border: 10px solid #e9edf0;
	-webkit-appearance: none;
	outline: none;
	cursor: default;
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
	margin-right: 15px;
}

input[type="radio"]:checked {
	background-color: rgba(0, 0, 0, 0.1);
	border: 10px solid #567da8;
}

::placeholder {
	color: #8892a6;
}

textarea {
	box-sizing: border-box;
	width: 100%;
	height: 250px;
	border: none;
	border-radius: 10px;
	margin-bottom: 20px;
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
	background-color: transparent;
	font-size: 17px;
	font-weight: bolder;
	padding-left: 25px;
	padding-right: 25px;
	color: #567da8;
	text-shadow: 0 1px 0 white;
	padding-top: 20px;
	-webkit-appearance: none;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
}

input[type="button"],
input[type="submit"],
input[type="reset"] {
	transition: box-shadow 0.25s ease;
	width: 100%;
	height: 50px;
	background-color: #e9edf0;
	border: none;
	margin-bottom: 20px;
	font-size: 18px;
	color: #587faa;
	font-weight: bold;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
}

input[type="button"]:hover,
input[type="submit"]:hover,
input[type="reset"]:hover {
	box-shadow: 10px 17px 21px -8px #97a6b3,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.8);
}

input[type="button"]:active,
input[type="submit"]:active,
input[type="reset"]:active {
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
}

input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="reset"]:disabled {
	background-color: white;
	box-shadow: none;
	opacity: 0.6;
}
