div.content {
	width: 90%;
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

p.largeTitle {
	font-size: 26px;
	font-weight: 700;
	text-transform: uppercase;
	margin: 0;
	margin-bottom: 10px;
}

p.text {
	margin: 0;
	margin-bottom: 2px;
	color: gray;
	font-size: 14px;
}
