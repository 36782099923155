.neo-cell-static {
	box-sizing: border-box;
	width: 100%;
	background-color: #e9edf0;
	font-size: 18px;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
	padding: 20px;
	margin-bottom: 20px;
	float: left;
}

.neo-cell {
	transition: all 0.25s ease;
	box-sizing: border-box;
	width: 100%;
	background-color: #e9edf0;
	font-size: 18px;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
	padding: 20px;
	margin-bottom: 20px;
	float: left;
}

.neo-cell:hover {
	box-shadow: 10px 17px 21px -8px #97a6b3,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.8);
	transform: scale(1.01);
}

.neo-cell:active {
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
}

.neo-cell-narrow {
	transition: all 0.25s ease;
	box-sizing: border-box;
	width: 100%;
	background-color: #e9edf0;
	font-size: 18px;
	border-radius: 10px;
	text-shadow: 0 1px white;
	box-shadow: 10px 10px 21px -8px #b6c6d4,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.57);
	padding-left: 10px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 20px;
	float: left;
}

.neo-cell-narrow:hover {
	box-shadow: 10px 17px 21px -8px #97a6b3,
		-10px -10px 20px 0 rgba(255, 255, 255, 0.8);
	transform: scale(1.01);
}

.neo-cell-narrow:active {
	box-shadow: inset -5px -5px 10px 0 rgba(250, 251, 255, 0.75),
		inset 5px 5px 10px 0 #d0dbe7;
}

.neo-cell-verticalContainer {
	display: flex;
	justify-content: center;
	flex-flow: column;
}

.neo-cell-horizontalContainer {
	display: flex;
	justify-content: space-between;
	flex-flow: row;
	align-items: center;
}

p.neo-cell-title {
	font-size: 18px;
	color: #587faa;
	font-weight: 700;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	user-select: none;
	cursor: default;
	margin-top: 0;
}

p.neo-cell-title-centered {
	font-size: 18px;
	color: #587faa;
	font-weight: 700;
	text-shadow: 0 1px white;
	user-select: none;
	cursor: default;
}

p.neo-cell-subtitle {
	font-size: 15px;
	color: #6d7278;
	font-weight: 500;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	user-select: none;
	cursor: default;
	margin-top: 5px;
}

p.neo-cell-statusLabel {
	font-size: 13px;
	color: #6d7278;
	font-weight: 600;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	user-select: none;
	cursor: default;
	line-height: 20px;
	margin-top: 7px;
	margin-left: 24px;
	text-transform: uppercase;
}

p.neo-cell-rightLabel {
	font-size: 15px;
	color: #6d7278;
	font-weight: 500;
	text-shadow: 0 1px white;
	margin: 0;
	user-select: none;
	cursor: default;
	text-align: right;
}

p.neo-cell-rightLabelBold {
	font-size: 18px;
	color: #587faa;
	font-weight: 700;
	text-shadow: 0 1px white;
	margin-bottom: 0;
	user-select: none;
	cursor: default;
	margin-top: 0;
	text-align: right;
}

p.neo-cell-statusLabel#open {
	color: #f4b816;
}

p.neo-cell-statusLabel#declined_by_user {
	color: #d82828;
}

p.neo-cell-statusLabel#id_card_not_readable {
	color: #d82828;
}

p.neo-cell-statusLabel#withdrawn_by_vendor {
	color: #663df8;
}

p.neo-cell-statusLabel#credit_check {
	color: #f36815;
}

p.neo-cell-statusLabel#completed {
	color: #60be0c;
}

p.neo-cell-statusLabel#credit_denied {
	color: #d82828;
}

p.neo-cell-statusLabel#id_card_check {
	color: #f36815;
}

p.neo-cell-statusLabel#expired {
	color: #d82828;
}

p.neo-cell-badge {
	min-width: 14px;
	height: 30px;

	font-size: 16px;
	color: #d82828;
	font-weight: 600;
	margin: 0;
	user-select: none;
	cursor: default;
	background-color: rgba(216, 40, 40, 0.2);
	text-align: center;
	border-radius: 15px;
	padding-left: 8px;
	padding-right: 8px;
	line-height: 31px;
	align-self: center;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.15);
	text-shadow: none;
}

.neo-cell-statusIcon {
	width: 20px;
	height: 20px;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	float: left;
	margin-top: 8px;
}
